


























































































































import { LimitedSubscriptionConfig, LimitedSubscriptionType } from '@/includes/types/Channel.types'
import formatTime from '@/includes/formatTime'
import { InputSetups } from '@/mixins/input-setups'
import { errorNotification, warningNotification } from '@/includes/services/NotificationService'
import PlaceholdersMixin from '@/mixins/PlaceholdersMixin'
import SaveChannelConfig from '@/components/SaveChannelConfig.vue'
import ChannelSubscriptionNotifies from "@/components/ChannelSubscriptionNotifies.vue";
import {
  FrontendNotify,
  toFrontendNotifies,
  toRawNotifies,
  validateNotifies
} from "@/includes/logic/ChannelSubscriptionNotifies/ChannelSubscriptionNotifiesService";
import NotifyValidationError from '@/includes/logic/ChannelSubscriptionNotifies/NotifyValidationError'
import { ChannelConfig } from '@/includes/types/Channel.types'

import { SelectOption } from 'piramis-base-components/src/logic/types'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import MultiMessageEditorWithMediaInput
  from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue'
import ExtendedHelpMessage from 'piramis-base-components/src/components/ExtendedHelpMessage.vue'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'
import CenteredColumnLayout from 'piramis-base-components/src/components/CenteredColumnLayout.vue'
import ActivationActionData
  from 'piramis-base-components/src/shared/modules/BotFeedback/ActivationAction/ActivationActionData.vue'

import { Component, Mixins, Ref } from 'vue-property-decorator'
import { cloneDeep, snakeCase } from 'lodash'
import Vue from 'vue'

@Component({
  components: {
    ChannelSubscriptionNotifies,
    SaveChannelConfig,
    PageTitle,
    ExtendedHelpMessage,
    MultiMessageEditorWithMediaInput,
    SelectInput,
    CenteredColumnLayout,
    ActivationActionData,
  },
  data() {
    return {
      LimitedSubscriptionType,
    }
  }
})
export default class ChannelSubscription extends Mixins(ModelSetter, InputSetups, PlaceholdersMixin) {

  @Ref('channel-sub-notifies') channelSubNotifiesRef!: ChannelSubscriptionNotifies

  channelConfig: ChannelConfig | null = null

  frontendNotifies: Array<FrontendNotify> = []

  getDefaultSubscriptionConfigStruct(): LimitedSubscriptionConfig {
    return {
      from: formatTime('09:00', 'HH:mm:ss'),
      to: formatTime('21:00', 'HH:mm:ss'),
      goodbye: null,
      notify: null,
      issue: null,
    }
  }

  get limitedSubscriptionType() {
    if (this.channelConfig) {
      return this.channelConfig.limited_subscription_type
    }

    return LimitedSubscriptionType.None
  }

  set limitedSubscriptionType(value: ChannelConfig['limited_subscription_type']) {
    if (this.channelConfig) {
      this.channelConfig.limited_subscription_type = value

      this.setCommentSubscriptionWarning(value)
    }
  }

  get subscriptionTypeOptions(): Array<SelectOption> {
    return Object.keys(LimitedSubscriptionType).map(k => ({
      label: this.$t(`field_${ snakeCase(k) }_title`).toString(),
      value: k,
    }))
  }

  get subscriptionTypeAlertMessage() {
    return this.$t(`subscription_type_${ snakeCase(this.limitedSubscriptionType.toLowerCase()) }_alert_message`).toString()
  }

  get subscriptionTypeIsNone() {
    return this.limitedSubscriptionType === LimitedSubscriptionType.None
  }

  get boardTitle() {
    return this.$store.getters.activeBoard?.title
  }

  highlightNotifyCard(guid: string): void {
    if (this.channelSubNotifiesRef) {
      const refs = this.channelSubNotifiesRef.$refs[`notify-${ guid }`] as Array<Vue> | undefined

      if (Array.isArray(refs) && refs.length) {
        const el = refs[0].$el

        el.classList.add('notify-card--invalid')

        setTimeout(() => {
          el.classList.remove('notify-card--invalid')
        }, 10000)
      }
    }
  }

  saveChannelConfig(): void {
    validateNotifies(this.frontendNotifies)
      .then(() => {
        this.$store.commit('pi/EXEC', {
          'fn': () => {
            const { activeChannel } = this.$store.state.channelsState

            if (activeChannel) {
              activeChannel.config = cloneDeep(this.channelConfig)

              if (activeChannel.config.limited_subscription_config) {
                activeChannel.config.limited_subscription_config.notify = toRawNotifies(this.frontendNotifies)
              }
            }
          },
        })

        this.$store.dispatch('save_active_channel_config', this.$route)
          .then(() => {
            this.setLocalSubscriptionConfig()
          })
      })
      .catch((e) => {
        if (e instanceof NotifyValidationError) {
          warningNotification(this.$t(e.message).toString())

          if (e.guids.length) {
            e.guids.forEach(this.highlightNotifyCard)

            if (typeof this.channelSubNotifiesRef?.SCROLL_TO_NOTIFY === 'function') {
              this.channelSubNotifiesRef.SCROLL_TO_NOTIFY(e.guids.at(-1)!)
            }
          }
        } else {
          errorNotification(e)
        }
      })
  }

  setCommentSubscriptionWarning(type: LimitedSubscriptionType) {
    if (type === LimitedSubscriptionType.Comments
      && this.channelConfig && this.channelConfig.limited_subscription_config
      && !this.channelConfig.limited_subscription_config.comments_subscription_warning
    ) {
      this.$set(this.channelConfig.limited_subscription_config, 'comments_subscription_warning', [])
    }
  }

  setLocalSubscriptionConfig() {
    if (this.$store.state.channelsState.activeChannel) {
      this.channelConfig = cloneDeep(this.$store.state.channelsState.activeChannel.config)

      if (this.channelConfig) {
        if (!this.channelConfig.limited_subscription_config) {
          this.channelConfig.limited_subscription_config = cloneDeep(this.getDefaultSubscriptionConfigStruct())

          this.setCommentSubscriptionWarning(this.channelConfig.limited_subscription_type)
        }

        this.frontendNotifies = toFrontendNotifies(this.channelConfig.limited_subscription_config.notify ?? {})
      }
    }
  }

  mounted(): void {
    this.$baseTemplate.saveButton.show(this.saveChannelConfig)

    this.setLocalSubscriptionConfig()
  }

  destroyed(): void {
    this.$baseTemplate.saveButton.hide()
    this.$baseTemplate.saveButton.resetSaveButtonCallback()
  }
}
